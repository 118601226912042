/* You can add global styles to this file, and also import other style files */
@import '@seech/core-ng/core';
@import '@seech/core-ng/theme';
@import '@seech/icons-ng';
@import 'theme';

@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown-label {
  font-size: 0.65rem;
  font-weight: 400;
  letter-spacing: 0.00875rem;
}

.dropdown-icon {
  font-size: 1rem !important;
  font-weight: 500;
}
